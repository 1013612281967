import Vue from 'vue' 
import Vuex from 'vuex'
import tab from './tab'
import business from './business'

Vue.use(Vuex)

export default new Vuex.Store(
  {
    modules:{
      tab,
      business
    }
  }
)