import api from '@/api/api';

export default {
  namespaced: true,
  state: {
    // 拨片数据
    pickList: [],
    // 鼓机分类数据
    drumCategories: [],
    // 数据加载状态
    dataLoaded: {
      pick: false,
      drum: false
    },

    pageConfig: {
      pageSize: 200,
      pageNum: 1
    }
  },
  mutations: {
    SET_PICK_LIST(state, list) {
      state.pickList = list;
      state.dataLoaded.pick = true;
    },
    SET_DRUM_CATEGORIES(state, categories) {
      state.drumCategories = categories;
      state.dataLoaded.drum = true;
    }
  },
  actions: {
    // 获取拨片列表
    async fetchPickList({ state, commit }) {
      if (state.dataLoaded.pick) return;
      try {
        const resp = await api.PICK_SEARCH({
          ...state.pageConfig
        });
        commit('SET_PICK_LIST', resp.data.data?.list ?? []);
      } catch (error) {
        console.error('获取拨片列表失败:', error);
      }
    },
    // 获取鼓机分类
    async fetchDrumCategories({ state, commit }) {
      if (state.dataLoaded.drum) return;
      try {
        const res = await api.DRUM_SEARCH({
          ...state.pageConfig
        });
        commit('SET_DRUM_CATEGORIES', res.data.data?.list ?? []);
      } catch (error) {
        console.error('获取鼓机分类失败:', error);
      }
    }
  },
  getters: {
    pickList: state => state.pickList,
    drumCategories: state => state.drumCategories,
    // 根据code获取拨片名称
    getPickNameByCode: state => code => {
      const pick = state.pickList.find(item => item.code === code);
      return pick ? pick.name : '未知拨片';
    },
    // 根据code获取鼓机名称
    getDrumNameByCode: state => code => {
      const drum = state.drumCategories.find(item => item.code === code);
      return drum ? drum.name : '未知鼓机';
    }
  }
} 