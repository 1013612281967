import axios from "axios";
// 引入所需的库
import CryptoJS from "crypto-js"; // 根据您的项目配置进行引入
// import { MessageBox } from "element-ui";
import { Message } from "element-ui";

const http = axios.create({
  //通用请求的地址前缀
  // baseURL:'/api',
  // baseURL: 'http://127.0.0.1:9999/',
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 10000, //超时时间
});

function sortObject(obj) {
  if (Array.isArray(obj)) {
    return obj.map(sortObject);
  } else if (typeof obj === "object" && obj !== null) {
    const sortedKeys = Object.keys(obj).sort();
    const sortedObj = {};
    sortedKeys.forEach((key) => {
      sortedObj[key] = sortObject(obj[key]);
    });
    return sortedObj;
  } else {
    return obj;
  }
}

// 添加请求拦截器
http.interceptors.request.use(
  (config) => {
    var sortedJsonString = "a";
    if ("post" === config.method) {
      var data = config.data;
      if (data !== "") {
        const sortedObject = sortObject(data);
        sortedJsonString = JSON.stringify(sortedObject);
      }
    } else {
      sortedJsonString = "";
    }

    var clientKey = "d9657b9601d140828b11f9c96653e7f8"; // Replace with your client key

    var clientSecret = "3ac8751c2a254a64ac6201684cbb84c0"; // Replace with your client secret

    var timestamp = Math.round(new Date());

    // 生成盐值（不抽取方法）
    const saltLength = 32;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+=~";
    let salt = "";
    for (let i = 0; i < saltLength; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      salt += charset.charAt(randomIndex);
    }

    var dataToSign = sortedJsonString + timestamp + salt + clientSecret;

    var sign = CryptoJS.MD5(dataToSign);

    config.headers["sign"] = sign.toString();
    config.headers["clientKey"] = clientKey;
    config.headers["timestamp"] = timestamp;
    config.headers["salt"] = salt;
    // 在请求头中添加 puserid
    const puserId = localStorage.getItem("pUserId");
    config.headers["puserId"] = puserId;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//响应拦截器
http.interceptors.response.use(
  (response) => {
    // 在这里检查返回数据的 code 值
    const responseData = response.data;
    if (responseData.code !== 1) {
      if (responseData.msg) {
        // 使用 Element UI 的 MessageBox 组件来弹窗报错
        // MessageBox.alert(responseData.msg, "错误提示", {
        //   type: "error",
        // });
        // Message.error(responseData.msg);
        Message({
          type: "error",
          message: responseData.msg,
          duration: 2000,
        });
      }
    }
    return response;
  },
  (error) => {
    // 在这里处理请求错误，比如网络错误、服务器错误等
    return Promise.reject(error);
  }
);

export default http;
